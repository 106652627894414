﻿.c-footer {
  .footer {
    background-color: var(--footer-header-bc);
    color: var(--white);
    position: relative;
    padding: ratio(8) 0 ratio(3);

    @include for-size("tablet-down") {
      padding: ratio(3) 0;
      overflow: hidden;
    }

    .footer__logo {
      position: absolute;
      inset: 0 0 0 auto;
      height: 100%;
      opacity: 0.1;

      @include for-size("tablet-down") {
        width: 100%;
        inset: auto 0 0 0;
      }
    }

    > .container {
      position: relative;
      z-index: 1;
    }

    .footer__newsletters-wrapper {
      @include for-size("tablet-down") {        
        padding-top: ratio(2);
      }
    }

    .footer__newsletters {
      display: flex;
      flex-direction: column;
      @include fontSize(13px);

      @include for-size("tablet-down") {
        flex-direction: column;
      }
    }

    .footer__newsletters-input {
      margin-top: ratio(3);
      @include flex-center-vertical;
      position: relative;

      input[type="text"] {
        height: 48px;
        padding-left: ratio(6);
      }

      button {
        min-width: initial;
        @include size(40px);
        position: absolute;
        left: 4px;
        padding: 0;
        @include fontSize(22px);
      }
    }

    .footer__more-links {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      width: 100%;
      margin-bottom: ratio(4);

      @include for-size("tablet-down") {
        margin-bottom: ratio(3);
        flex-direction: column;
      }

      ul {
        @include for-size("tablet-down") {
          padding-top: ratio(2);
        }
      }

      li {
        &:not(:last-child) {
          margin-bottom: ratio(0.5);
        }

        a {
          display: block;
          @include fontSize(13px);
          line-height: 2.4;
          color: var(--gray-ee);
          transition: all 0.25s;

          &:hover {
           color: var(--text-primary);
          }
        }
      }
    }

    .footer__more-links-title {
      @include flex-center-vertical;
      justify-content: space-between;
      position: relative;
      padding-bottom: ratio(1);
      margin-bottom: ratio(2);
      font-weight: bold;
      @include fontSize(14px);

      @include for-size("tablet-down") {
        margin: 0;
        padding: ratio(2) 0;
      }

      &::after {
        content: "";
        background-color: var(--primary-color);
        @include size(45px, 3px);
        position: absolute;
        bottom: 0;
        right: 0;

        @include for-size("tablet-down") {
          @include size(100%, 1px);
          background-color: var(--color-5);
        }
      }

      i {
       color: var(--text-primary);

        @include for-size("desktop") {
          display: none;
        }
      }
    }

    .footer__more-links-item {
      width: calc((100% - 400px) / 5);

      @include for-size("tablet-down") {
        width: 100%;
      }

      &:not(:first-child) {
        ul {
          @include for-size("tablet-down") {
            display: none;
          }
        }
      }
    }

    .footer__more-links-item--enamad {
      width: 400px;
    }

    .footer__enamads {
      @include flex-center-vertical;
      flex-direction: row-reverse;
      flex-wrap: wrap;
      margin-top: ratio(3);

      @include for-size("tablet-down") {
        justify-content: center;
      }
    }

    .footer__enamad-item {
      @include size(88px);
      @include flex-center;
      background-color: var(--white);
      border-radius: 4px;
      padding: ratio(0.5);
      overflow: hidden;

      @include for-size("tablet-down") {
        margin: ratio(0.5);
        @include size(80px);
      }

      &:not(:nth-child(4n)) {
        @include for-size("desktop") {
          margin-right: ratio(2);
        }
      }

      &:nth-child(n + 5) {
        @include for-size("desktop") {
          margin-top: ratio(2);
        }
      }

      a {
        @include flex-center;
        @include size(100%);
      }

      img {
        max-height: 100%;
      }
    }

    .footer__description {
      border-top: 1px solid rgba(var(--color-5-rgb), 0.3);
      border-bottom: 1px solid rgba(var(--color-5-rgb), 0.3);
      padding: ratio(3) 0;
      margin-bottom: ratio(3);
      text-align: justify;

      @include for-size("tablet-down") {
        display: none;
      }

      p {
        line-height: 2.4;
        @include fontSize(11px);
        margin: 0;
      }
    }

    .footer__copy-right-row {
      @include flex-center-vertical;
      justify-content: space-between;
      width: 100%;

      @include for-size("tablet-down") {
        flex-direction: column;
        text-align: center;
      }

      p {
        @include fontSize(11px);
        margin: 0;

        @include for-size("tablet-down") {
          @include fontSize(12px);
          line-height: 2;
        }

        span {
          display: inline;
        }
      }

      a {
       color: var(--text-primary);
      }
    }

    .footer_share-links {
      display: flex;
      direction: ltr;

      @include for-size("tablet-down") {
        margin-top: ratio(3);
      }

      li {
        a {
          color: var(--gray-cc);
          display: flex;

          &:hover {
           color: var(--text-primary);
          }

          i {
            @include fontSize(24px);
            margin-right: ratio(2.5);
          }
        }
      }
    }
  }
}
