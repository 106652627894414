﻿//grid mixin
$max: 12;

@mixin grid($screen) {
  @for $i from 1 through $max {
    .col-#{$screen}--#{$max} {
      flex-basis: percentage($max / 12);
      max-width: percentage($max / 12);
      width: percentage($max / 12);
    }

    $max: $max - 1;
  }
}

/* media query
-----------------------------------------------------------*/
@mixin for-size($size) {
  @if $size==phone-only {
    @media (max-width: 767px) {
      @content;
    }
  } @else if $size==not-phone {
    @media (min-width: 768px) {
      @content;
    }
  } @else if $size==tablet-only {
    @media (min-width: 768px) and (max-width: 992px) {
      @content;
    }
  } @else if $size==desktop-medium {
    @media (min-width: 993px) and (max-width: 1180px) {
      @content;
    }
  } @else if $size==tablet-down {
    @media (max-width: 992px) {
      @content;
    }
  } @else if $size==desktop-small {
    @media (max-width: 1180px) {
      @content;
    }
  } @else if $size==desktop-big {
    @media (min-width: 1181px) {
      @content;
    }
  } @else if $size==desktop {
    @media (min-width: 993px) {
      @content;
    }
  }
}

// Minimum breakpoint width.
@function breakpoint-min($name, $breakpoints: $grid-breakpoints) {
  $min: map-get($breakpoints, $name);
  @return if($min !=0, $min, null);
}

@function breakpoint-infix($name, $breakpoints: $grid-breakpoints) {
  @return if(breakpoint-min($name, $breakpoints) ==null, "", "-#{$name}");
}

@mixin media-breakpoint-up($name, $breakpoints: $grid-breakpoints) {
  $min: breakpoint-min($name, $breakpoints);

  @if $min {
    @media (min-width: $min) {
      @content;
    }
  } @else {
    @content;
  }
}

// css triangle
@mixin triangle($direction: down, $size: 5px, $color: #000) {
  content: "";
  position: absolute;
  @include size(0px);
  border: $size solid transparent;
  border-bottom-color: $color;
  border-left-color: $color;

  @if ($direction == left) {
    left: -$size;
    top: 50%;
    transform: translateY(-50%) rotate(45deg);
  } @else if ($direction == right) {
    right: -$size;
    top: 50%;
    transform: translateY(-50%) rotate(-135deg);
  } @else if ($direction == down) {
    bottom: -$size;
    right: 50%;
    transform: translateX(50%) rotate(-45deg);
  } @else {
    top: -$size;
    right: 50%;
    transform: translateX(50%) rotate(-225deg);
  }
}


// flex center vertical
@mixin flex-center-vertical {
  display: flex;
  align-items: center;
}

// flex center horizontal
@mixin flex-center-horizontal {
  display: flex;
  justify-content: center;
}

// flex center
@mixin flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

//placeholder
@mixin placeholder {
  &::-webkit-input-placeholder {
    @content;
  }

  &:-moz-placeholder {
    @content;
  }

  &::-moz-placeholder {
    @content;
  }

  &:-ms-input-placeholder {
    @content;
  }
}

@mixin truncate() {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin size($width, $height: $width) {
  width: $width;
  height: $height;
}

@function calculateRem($size) {
  $remSize: $size / $rootFontSize;
  @return #{$remSize}rem;
}

@mixin fontSize($size) {
  font-size: $size;
  font-size: calculateRem($size);
}

@function ratio($size) {
  $size: $size * $spacer;
  @return #{$size}px;
}

@mixin css-triangle($triangle-size, $triangle-color, $triangle-direction) {
  display: inline-block;
  width: 0;
  height: 0;
  border: inset $triangle-size;
  content: "";

  @if ($triangle-direction==down) {
    border-bottom-width: 0;
    border-top-style: solid;
    border-color: $triangle-color transparent transparent;
  }

  @if ($triangle-direction==up) {
    border-top-width: 0;
    border-bottom-style: solid;
    border-color: transparent transparent $triangle-color;
  }

  @if ($triangle-direction==right) {
    border-right-width: 0;
    border-left-style: solid;
    border-color: transparent transparent transparent $triangle-color;
  }

  @if ($triangle-direction==left) {
    border-left-width: 0;
    border-right-style: solid;
    border-color: transparent $triangle-color transparent transparent;
  }
}

@mixin gutter($prop, $num) {
  $prop: ratio($num);
}