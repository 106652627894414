.c-description {
  .description {
    margin-top: ratio(6);

    @include for-size("tablet-down") {
      margin-top: ratio(3);
      padding: 0 ratio(1.5);
    }

    & > .container {
      background-color: var(--white);
      box-shadow: 0px 1px 2px 0 rgba(var(--black-rgb), 0.1);
      border-radius: 4px;
      padding: ratio(4) !important;

      @include for-size("tablet-down") {
        padding: ratio(2) !important;
      }
    }

    .description__content {
      transform-origin: 0 top;
      transition: max-height 0.5s ease-in-out;
      max-height: 232px;
      overflow: hidden;
      position: relative;

      ul {
        li {
          list-style-type: disc;
          list-style-position: inside;
        }
        margin-bottom: ratio(3);
      }

      ol {
        li {
          list-style-type: numeric;
          list-style-position: inside;
        }
        margin-bottom: ratio(3);
      }
    }

    .is-open {
      max-height: initial;
    }

    .description__item {
      &:not(:last-child) {
        padding-bottom: ratio(3);
        margin-bottom: ratio(3);
        border-bottom: 1px solid var(--gray-ee);

        @include for-size("tablet-down") {
          padding-bottom: ratio(2);
          margin-bottom: ratio(2);
        }
      }
    }

    p {
      @include fontSize(15px);
      line-height: 2;
      margin: 0;

      @include for-size("tablet-down") {
        @include fontSize(14px);
      }

      &:not(:last-child) {
        margin-bottom: ratio(2);
      }
    }

    button {
      width: auto;
      min-width: auto;
      padding: 0;

      i {
        margin-right: ratio(1);
        @include fontSize(16px);
      }

      span {
        @include flex-center;
      }

      .description__content-up {
        display: none;
      }

      &.active {
        .description__content-down {
          display: none;
        }

        .description__content-up {
          display: flex;
        }
      }
    }

    h1,
    .h1,
    h2,
    .h2,
    h3,
    .h3,
    h4,
    .h4,
    h5,
    .h5 {
      margin-bottom: ratio(2);
    }

    h1,
    .h1 {
      @include fontSize(24px);
    }

    h2,
    .h2 {
      @include fontSize(20px);
    }

    h3,
    .h3 {
      @include fontSize(18px);
      margin-bottom: ratio(2.5);
    }
  }
}
