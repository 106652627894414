﻿html {
  font-size: $rootFontSize;
  scroll-behavior: smooth;
}

body {
  font-family: var(--fontFamily);
  font-size: $rootFontSize !important;
  color: var(--gray-33);
  background-color: var(--gray-f6);

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

button,
input,
select,
a {
  font-family: var(--fontFamily);
}

.container {
  max-width: 100%;
  padding-right: 10px;
  padding-left: 10px;
  margin-right: auto;
  margin-left: auto;
  flex-grow: 1;

  @include for-size("desktop") {
    width: 98%;
  }

  @include for-size("desktop-big") {
    width: 1320px;
  }
}

[class*="col-"] {
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%;
  box-sizing: border-box;
  flex: 0 0 auto;
  padding-right: 10px;
  padding-left: 10px;
  width: 100%;
  position: relative;
}

@include for-size("phone-only") {
  /*max-width:767*/
  @include grid(xs);
}

@include for-size("not-phone") {
  /*min-width:768*/
  @include grid(sm);
}

@include for-size("desktop") {
  /*min-width:992px*/
  @include grid(md);
}

@include for-size("desktop-big") {
  /*min-width:1321px*/
  @include grid(lg);
}

.row {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-right: -10px;
  margin-left: -10px;
}

a {
  color: var(--blue);
}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5 {
  margin: 0 0 ratio(3);

  @include for-size("tablet-down") {
    margin-bottom: ratio(2);
  }
}

h1,
.h1 {
  @include fontSize(31px);
}

h2,
.h2 {
  @include fontSize(24px);
}

h3,
.h3 {
  @include fontSize(20px);
}

h4,
.h4 {
  @include fontSize(15px);
}

h5,
.h5 {
  @include fontSize(12px);
}

.enNum {
  font-family: var(--fontFamilyEnNum) !important;
}

.faNum {
  font-family: var(--fontFamily) !important;
}

.no-scroll {
  overflow: hidden !important;
}

.hidden,
.hide {
  display: none !important;
}

.show {
  display: block !important;
}

.dis-flex {
  display: flex;
}

.flex-wrap {
  flex-wrap: wrap;
}

.dis-block {
  display: block;
}

.flex-col {
  flex-direction: column;
}

.flex-show {
  display: flex !important;
}

.relative {
  position: relative;
}

.text-center {
  text-align: center;
}

.text-justify {
  text-align: justify;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.bg-white {
  background-color: var(--white);
}

.line-through {
  text-decoration: line-through;
}

.dir-ltr {
  direction: ltr;
}

.dir-rtl {
  direction: rtl;
}

.hide-in-desktop {
  @include for-size("desktop") {
    display: none !important;
  }
}

.hide-in-phone {
  @include for-size(phone-only) {
    display: none !important;
  }
}

.hide-in-not-phone {
  @include for-size(not-phone) {
    display: none !important;
  }
}

.hide-in-not-desktop {
  @include for-size("tablet-down") {
    display: none !important;
  }
}

.main-container {
  min-height: 400px;

  @include for-size("tablet-down") {
    min-height: 150px;
  }
}

.rounded {
  border-radius: 4px;
}

.select2-container {
  @include fontSize(14px);
}

.span.select2-container {
  .select2-selection--single {
    border: solid 1px var(--gray-e6);
    background-color: var(--white);
    box-shadow: 0px 1px 2px 0 rgba(var(--black-rgb), 0.1);
    height: 38px;

    .select2-selection__rendered {
      height: 100%;
      padding-right: ratio(1);
      padding-left: ratio(6);
      text-align: left;
      line-height: 38px;

      .select2-selection__placeholder {
        @include fontSize(12px);
      }
    }

    .select2-selection__arrow {
      left: auto;
      right: 1px;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .select2-results__option {
    text-align: left;
    @include fontSize(13px);
  }
}

.is-fixed {
  position: fixed !important;
}

.select2 {
  width: 100% !important;
}

.bold {
  font-weight: bold;
}

.w-100 {
  width: 100% !important;
}

.h-100 {
  height: 100% !important;
}

.text-red {
  color: var(--red);
}

.text-yellow {
  color: var(--yellow1);
}

.text-blue {
  color: var(--blue);
}

.text-black {
  color: var(--gray-16);
}

.text-gray {
  color: var(--gray-99);
}

.daterangepicker {
  border: 1px solid var(--gray-e6);
  display: none;
}

//icon star
.icon-star-yellow {
  color: var(--yellow1);

  &:before {
    content: $icon-star;
  }
}

.icon-half-star-yellow {
  color: var(--yellow1);

  &:before {
    content: $icon-star-half;
  }
}

//here what i commented NAVID
// Margin and Padding
@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

    @each $prop, $abbrev in (margin: m, padding: p) {
      @each $size, $length in $spacers {
        .#{$abbrev}#{$infix}--#{$size} {
          #{$prop}: $length + px !important;
        }

        .#{$abbrev}t#{$infix}--#{$size},
        .#{$abbrev}y#{$infix}--#{$size} {
          #{$prop}-top: $length + px !important;
        }

        .#{$abbrev}r#{$infix}--#{$size},
        .#{$abbrev}x#{$infix}--#{$size} {
          #{$prop}-right: $length + px !important;
        }

        .#{$abbrev}b#{$infix}--#{$size},
        .#{$abbrev}y#{$infix}--#{$size} {
          #{$prop}-bottom: $length + px !important;
        }

        .#{$abbrev}l#{$infix}--#{$size},
        .#{$abbrev}x#{$infix}--#{$size} {
          #{$prop}-left: $length + px !important;
        }
      }
    }

    // Some special margin utils
    .m--auto {
      margin: auto !important;
    }

    .mt--auto,
    .my--auto {
      margin-top: auto !important;
    }

    .mr--auto,
    .mx--auto {
      margin-right: auto !important;
    }

    .mb--auto,
    .my--auto {
      margin-bottom: auto !important;
    }

    .ml--auto,
    .mx--auto {
      margin-left: auto !important;
    }
  }
}

.p-sm--0 {
  @media screen and (min-width: 769px) {
    padding: 0 !important;
  }
}

.mt-sm--6 {
  @media screen and (min-width: 769px) {
    margin-top: ratio(6) !important;
  }
}

.mt-sm--0 {
  @media screen and (min-width: 769px) {
    margin-top: 0 !important;
  }
}

.mt--n10 {
  margin-top: ratio(-10);
}

.mt-md--n10 {
  @media screen and (min-width: 993px) {
    margin-top: ratio(-10);
  }
}

.mt--n8 {
  margin-top: ratio(-8);
}

.ml--half {
  margin-left: ratio(0.5);
}

// bootstrap notify and alert
[data-notify="container"] {
  max-width: 450px;
  left: 16px !important;
  right: auto !important;

  @include for-size("tablet-down") {
    max-width: calc(100% - 32px);
    right: 16px !important;
    bottom: 68px !important;
    top: auto !important;
    background-color: var(--white) !important;
  }
}

// range slider
.range-slider {
  .slider.slider-horizontal {
    width: 100% !important;
  }

  .slider-handle {
    background: var(--red);
    transform: translateY(-50%);
    margin-top: 1px;
  }

  .slider-selection {
    background: var(--gray-e6);
  }

  .slider-track {
    height: 2px !important;
    margin: 0 !important;
    top: 0 !important;
  }
}

.bullet-list {
  margin-bottom: ratio(3);

  @include for-size("tablet-down") {
    margin-bottom: ratio(2);
  }

  li {
    list-style: disc;
    list-style-position: inside;
  }
}

//demo gallery
div.demo-gallery {
  @include for-size("desktop") {
    margin: ratio(5) 0;
  }

  & > ul {
    display: flex;
    flex-wrap: wrap;

    & > li {
      float: right;
      cursor: pointer;

      @include for-size(not-phone) {
        width: calc(100% / 5);
      }

      @include for-size(phone-only) {
        width: calc(100% / 3);
      }

      a {
        display: flex;
        float: initial;
      }
    }
  }
}

.table {
  overflow-x: auto;
}

.table {
  width: 100%;
  max-width: 100%;
  @include fontSize(12px);
  line-height: 1.7;
  margin-bottom: ratio(3);
  border: 1px solid var(--gray-ee);

  @include for-size("tablet-down") {
    margin-bottom: ratio(2);
  }

  thead {
    background-color: var(--gray-33);
    color: var(--white);

    tr {
      border-bottom: 1px solid var(--white);
    }

    a {
      transition: all 0.25s ease-in;
      color: var(--white);
      text-decoration: underline;

      &:hover {
        color: var(--blue);
      }
    }
  }

  tr {
    transition: all 0.25s ease-in;

    &:not(:last-child) {
      border-bottom: 1px solid var(--gray-ee);
    }
  }

  tbody {
    &:not(:last-child) {
      border-bottom: 2px solid var(--gray-ee);
    }

    tr {
      vertical-align: baseline;

      &:hover {
        background-color: var(--gray-f9);
      }
    }
  }

  th {
    padding: ratio(1);

    &:not(:last-child) {
      border-left: 1px solid var(--white);
    }
  }

  td {
    padding: ratio(1.5) ratio(1);

    @include for-size("tablet-down") {
      min-width: 100px;
    }

    &:not(:last-child) {
      border-left: 1px solid var(--gray-ee);
    }
  }

  .icon-check {
    color: var(--color-4);
  }

  .icon-close {
    color: var(--red);
  }
}

.owl-stage {
  padding: ratio(0.5) 0;
}

.admin-module-wrapper {
  * {
    &:not(.footer, .footer *) {
      margin: 0 !important;
    }
  }
}

body {
  & > p {
    margin: 0 !important;
  }
}

//score
.booking-score {
  display: flex;
  font-family: var(--fontFamilyEnNum);
  background-color: var(--white);
  border-radius: 4px;
  box-shadow: 0px 1px 2px 0 rgba(var(--black-rgb), 0.1);
  padding: ratio(2);

  span {
    background-color: var(--blue-cerulean);
    min-width: 32px;
    height: 32px;
    color: var(--white);
    margin-left: ratio(1);
    padding: 0 ratio(0.5);
    font-weight: bold;
    @include fontSize(12px);
    @include flex-center;
    border-radius: 5px 5px 0 5px;

    @include for-size("tablet-down") {
      min-width: 24px;
      height: 24px;
    }
  }

  ul {
    height: 32px;
  }

  li {
    color: var(--gray-99);
    font-weight: normal;
    line-height: 1.45;
    @include fontSize(11px);
  }
}

.custom-scroll-bar {
  &::-webkit-scrollbar-track {
    border-radius: 5px;
  }

  &::-webkit-scrollbar {
    width: 7px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background-color: var(--gray-c6);
  }

  &::-moz-scrollbar-track {
    border-radius: 5px;
  }

  &::-moz-scrollbar {
    width: 7px;
    background-color: transparent;
  }

  &::-moz-scrollbar-thumb {
    border-radius: 5px;
    background-color: var(--gray-ee);
  }
}

.custom-horizontal-scroll-bar {
  &::-webkit-scrollbar {
    height: 5px;
  }

  &::-webkit-scrollbar-track {
    background-color: var(--white);
  }

  &::-webkit-scrollbar-thumb {
    height: 5px;
    background-color: var(--gray-c6);
    border-radius: 5px;
  }

  &::-webkit-scrollbar:vertical {
    display: none;
  }
}
