﻿.c-nav-bar {
  .nav-bar {
    display: flex;

    & > li {
      position: relative;
      @include flex-center-vertical;

      &:hover {
        &::before {
          @include for-size("desktop") {
            width: 100%;
          }
        }
      }

      &::before {
        background-color: var(--primary-color);
        content: "";
        @include size(0, 3px);
        position: absolute;
        top: 0;
        right: 50%;
        transform: translateX(50%);
        transition: all 0.25s linear;
      }

      &:not(:last-child) {
        margin-left: ratio(6);

        @include for-size("tablet-down") {
          margin-left: ratio(3);
        }

        @include for-size("phone-only") {
          margin-left: ratio(1);
        }
      }

      &:hover {
        & > a.nav-bar__menu-item {
          &:after {
            transform: translateY(-50%) rotate(180deg);
          }
        }

        & > ul {
          opacity: 1;
          visibility: visible;
          margin-top: -3px;
        }
      }

      &:first-child {
        margin-left: ratio(2.5);

        &::after {
          content: "";
          @include size(1px, 24px);
          border-left: 1px solid var(--primary-color);
          position: absolute;
          left: 0;
        }

        .nav-bar__menu-item {
          span {
            @include fontSize(0px);
          }
        }
      }

      & > ul {
        visibility: hidden;
        opacity: 0;
        min-width: 258px;
        border-radius: 2px;;
        box-shadow: 0 2px 8px 0 rgba(var(--black-rgb), 0.08);
        transition: all 0.25s;
        background-color: var(--white);
        position: absolute;
        top: 100%;
        margin-top: ratio(1);
        z-index: 10;
        padding: ratio(1) 0;

        @include for-size("tablet-down") {
          right: 0;
          width: 100%;
        }

        li {
          a {
            @include fontSize(12px);
            line-height: 1.92;
            color: var(--gray-dark);
            padding: ratio(0.5) ratio(3) 0 ratio(3);
            display: block;

            &:hover {
             color: var(--text-primary);
            }
          }
        }
      }
    }

    .nav-bar__menu-item {
      color: var(--gray-70);
      position: relative;
      padding-right: ratio(4.5);
      transition: all 0.25s;
      @include fontSize(14px);
      @include flex-center-vertical;
      height: 60px;

      @include for-size("tablet-down") {
        padding-right: ratio(3.5);
        @include fontSize(12px);
        height: 48px;
      }

      &:hover {
        color: var(--gray-33);
      }

      &:before {
        content: "";
        position: absolute;
        @include size(22px);
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        @include fontSize(22px);
        @include flex-center;
        font-family: $icomoon-font-family;

        @include for-size("tablet-down") {
          @include fontSize(16px);
        }
      }

      i {
        margin-left: ratio(2);
        @include fontSize(24px);
      }
    }

    .nav-bar__menu-item--has-sub-menu {
      padding-left: ratio(3);

      &:after {
        content: $icon-arrow-down;
        font-family: $icomoon-font-family;
        position: absolute;
        top: 50%;
        transform: translateY(-50%) rotate(0);
        left: 1px;
        @include fontSize(18px);
        transition: transform 0.25s;
      }
    }

    .nav-bar__blog-link {
     color: var(--text-primary);
      position: relative;
      padding-top: ratio(1);

      @include for-size("desktop") {
        margin-top: ratio(1);
      }

      &:after {
        @include for-size("desktop") {
          content: "";
          width: 90%;
          top: 0;
          height: 1px;
          border-top: 1px solid var(--gray-e6);
          right: 0;
          left: 0;
          margin: auto;
          position: absolute;
        }
      }

      span {
        position: relative;
        padding-left: ratio(2);

        &:after {
          content: $icon-arrow-down;
          font-family: $icomoon-font-family;
          position: absolute;
          top: 50%;
          transform: translateY(-50%) rotate(90deg);
          left: 1px;
          @include fontSize(14px);
        }
      }
    }

    .nav-bar__home {
      & > a:before {
        content: $icon-home;
      }
    }

    .nav-bar__hotel {
      & > a:before {
        content: $icon-office;
      }
    }

    .nav-bar__plane {
      & > a:before {
        content: $icon-international-plane;
      }
    }

    .nav-bar__tour {
      & > a:before {
        content: $icon-backpack;
      }
    }

    .nav-bar__blog {
      & > a:before {
        content: $icon-news;
      }

      .nav-bar__menu-item {
        position: relative;
      }
    }

    .nav-bar__cip {
      & > a:before {
        content: $icon-cip;
      }
    }
    
    .nav-bar__insurance {
      & > a:before {
        content: $icon-insurance;
      }
    }
  }
}
