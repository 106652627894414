.c-banner {
  .banner {
    background-color: rgba(var(--white-rgb), 0.55);

    @include for-size("desktop") {
      position: absolute;
      inset: 0 0 auto;
    }

    @include for-size("tablet-down") {
      margin: ratio(-1) ratio(-1) ratio(1);
    }

    &.is-relative {
      position: relative;
      inset: initial;

      @include for-size("tablet-down") {
        margin: 0;
      }
    }

    .container {
      @include fontSize(14px);
      position: relative;
      @include flex-center-vertical;
      flex-wrap: wrap;
      padding: ratio(1) 0 ratio(1) ratio(3);

      @include for-size("tablet-down") {
        padding: ratio(1) ratio(1) ratio(1) ratio(3);
      }

      a {
        text-decoration: underline;
      }
    }

    .banner__close {
      background: none;
      border: none;
      @include flex-center;
      @include fontSize(22px);
      padding: 0;
      position: absolute;
      left: 0;
      cursor: pointer;

      @include for-size("tablet-down") {
        left: 8px;
        top: 8px;
      }
    }
  }
}
