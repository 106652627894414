﻿html,
body {
  direction: rtl;
  margin: 0;
  scroll-behavior: smooth;
}

i {
  font-style: normal;
}

ul,
li {
  list-style: none;
}

ul {
  padding: 0;
  margin: 0;
}

*:focus {
  outline: none;
}

table {
  border-collapse: collapse;
}

img {
  max-width: 100%;
  height: auto;
}

a {
  text-decoration: none;
}

button {
  border: none;
  background-color: transparent;
  background: none;
  cursor: pointer;
}

* {
  box-sizing: border-box;
  box-shadow: none;
}

hr {
  content: "";
  border: none;
  @include size(100%, 1px);
  margin: ratio(3) 0;
  background-color: var(--gray-ee);
}
