.c-popular-tourism {
  .popular-tourism {
    margin-top: ratio(6);
    margin-bottom: ratio(7);

    @include for-size("tablet-down") {
      margin-top: ratio(3);
      padding: 0 ratio(2);
      margin-bottom: ratio(4);
    }

    .popular-tourism__title {
      @include fontSize(19px);
      margin: 0 0 ratio(3);
    }

    .popular-tourism__conatiner {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      margin: 0 ratio(-0.75);

      @include for-size("tablet-down") {
        margin: 0 ratio(-0.5);
      }

      & > a {
        height: 56px;
        box-shadow: 0px 1px 2px 0 rgba(var(--black-rgb), 0.1);
        border-radius: 4px;
        background-color: var(--white);
        transition: background-color 0.4s, color 0.25s;
        color: var(--gray-dark);
        width: calc((100% / 6) - 12px);
        margin: 0 ratio(0.75) ratio(1);
        padding: ratio(1);
        line-height: 1.5;
        @include fontSize(13px);
        font-weight: bold;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        @include for-size("tablet-down") {
          min-width: calc((100% / 2) - 8px);
          margin: 0 ratio(0.5) ratio(1);
        }

        &:hover {
          background-color: var(--primary-color);
          color: var(--white);

          span {
            &:first-child {
              color: var(--white);
            }

            &:last-child {
              color: var(--gray-f6);
            }
          }
        }

        @include for-size("desktop") {
          &:nth-child(n + 18) {
            display: none;
            & + .popular-tourism__btn-more {
              display: block;
            }
          }
        }

        @include for-size("tablet-down") {
          &:nth-child(n + 8) {
            display: none;
            & + .popular-tourism__btn-more {
              display: block;
            }
          }
        }

        span {
          display: block;
          line-height: 1.5;

          &:first-child {
            @include fontSize(13px);
            font-weight: bold;
            width: 100%;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }

          &:last-child {
            margin-top: ratio(0.5);
            @include fontSize(10px);
            color: var(--gray-99);
          }
        }
      }
    }

    .popular-tourism__btn-more {
      border: none;
      background-color: transparent;
      line-height: 1;
      margin-bottom: ratio(1);
     color: var(--text-primary);
      display: none;
      cursor: pointer;
    }
  }
}
