﻿// @import "../Variables/variables";
// @import "../Mixins/mixins";

//inputs
/*input:not([type="submit"]):not([type="checkbox"]):not([type="radio"]) {*/
input[type="text"],
input[type="password"],
input[type="email"],
input[type="tel"],
input[type="number"],
textarea,
select {
  border: solid 1px var(--gray-e6);
  background-color: var(--white);
  padding: 0 ratio(1);
  line-height: 1.5;
  color: var(--gray-dark);
  background-clip: padding-box;
  font-family: var(--fontFamily);
  @include size(100%, 40px);
  border-radius: 4px;
  transition: border-color 0.15s ease-in-out;

  &:focus {
    border: 1px solid var(--primary-color);
  }

  &.success {
    border-color: var(--color-4);
  }

  &.error,
  &.input-validation-error,
  &.validation--error,
  &.validation-error {
    border-color: var(--color-6);
  }

  &:disabled {
    background-color: var(--gray-f9);
  }

  @include placeholder {
    color: var(--gray-99);
    @include fontSize(14px);
    text-align: right;
  }
}

input[type="password"],
input[type="email"],
input[type="tel"],
input[type="number"] {
  direction: ltr;
  text-align: left;

  @include placeholder {
    direction: rtl;
    text-align: right;
  }
}

input[type="email"] {
  font-family: var(--fontFamilyEnNum);
}

textarea {
  @include size(100%, auto);
}

textarea {
  border-radius: 4px;
  padding: ratio(1);
  border: solid 1px var(--gray-e6);
}

//breadcrumb
.breadcrumb {
  display: flex;

  @include for-size("tablet-down") {
    flex-wrap: wrap;
  }

  &.breadcrumb--white {
    li {
      &:last-child {
        a {
          color: rgba(var(--white-rgb), 0.8);
        }
      }

      a {
        color: var(--white);
      }
    }
  }

  li {
    position: relative;
    display: inline-flex;
    align-items: center;
    padding: 0 ratio(1.5);

    @include for-size("tablet-down") {
      white-space: nowrap;
    }

    &:last-child {
      span {
        color: var(--gray-99);
        @include fontSize(12px);
        @include flex-center-vertical;
      }
    }

    &:first-child {
      a {
        padding-right: 0;
      }
    }

    a {
      @include fontSize(12px);
      line-height: 1.73;
      position: relative;
      color: var(--gray-16);
      @include flex-center-vertical;
    }

    &:not(:last-child) {
      &:after {
        content: $icon-arrow-left;
        font-family: $icomoon-font-family;
        position: absolute;
        width: 5px;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        @include fontSize(12px);
      }
    }
  }

  .breadcrumb-item--dropdown {
    & > span {
      @include flex-center-vertical;
    }

    &.open {
      .breadcrumb-item__title {
        & > * {
          color: var(--text-primary);
        }
      }

      .breadcrumb__dropdown-selector {
        display: block;
      }

      .breadcrumb__dropdown-button {
        transform: rotate(180deg);
      }
    }
  }

  .breadcrumb__dropdown-button {
    font-family: $icomoon-font-family;
    @include size(11px);
    @include fontSize(11px);
    margin-right: ratio(1);
    font-weight: bold;
    padding: 0;
    transition: 0.25s;
    color: var(--gray-16);
  }

  .breadcrumb__dropdown-selector {
    display: none;
    position: absolute;
    top: calc(100% + ratio(1));
    right: 0;
    min-width: 160px;
    background-color: var(--white);
    border: 1px solid var(--gray-ee);
    border-radius: 4px;
    padding: ratio(1.5);
    z-index: 1;
    box-shadow: 0 2px 8px 0 rgba(var(--black-rgb), 0.08);

    & > a {
      transition: 0.25s;

      &:not(:last-child) {
        margin-bottom: ratio(1.5);
      }

      &:hover {
        color: var(--text-primary);
        transition: 0.25s;
      }
    }
  }
}

//forms
.form {
  label {
    @include fontSize(13px);
    margin-bottom: ratio(0.5);
    display: inline-block;
  }

  .form-validation {
    color: var(--red);
    @include fontSize(12px);
  }

  hr {
    margin: 0;
    border-width: 0 0 1px 0;
    border-color: rgba(var(--black-rgb), 0.1);
    width: 100%;
  }

  .form-element-group--with-icon {
    & > div {
      @include for-size("desktop") {
        position: relative;
      }
    }

    input:not([type="submit"]),
    select.form-element {
      @include for-size("desktop") {
        padding-right: ratio(6);
      }
    }

    i {
      position: absolute;
      top: 50%;
      right: 10px;
      @include fontSize(24px);
      color: var(--gray-99);
      transform: translateY(-50%);
    }

    span .select2-container .select2-selection--single .select2-selection__rendered {
      padding-left: ratio(6);
    }
  }

  .pseudo-input {
    position: absolute;
    left: 70px;
    top: 10px;
    display: flex;
    @include fontSize(12px);
  }

  .form__row {
    margin-bottom: ratio(2);
  }
}

//captcha
.captcha-box {
  @include size(100%, 38px);
  border: 1px solid var(--gray-e6);
  @include flex-center-vertical;
  justify-content: space-between;
  border-radius: 4px;
  flex-direction: row-reverse;
  background-color: var(--white);

  img {
    height: 100%;
  }

  input[type="text"] {
    border: none;
    box-shadow: none;
    height: 35px;
  }

  & > div:first-child {
    flex-grow: 1;
    position: relative;
    padding-left: ratio(3);
    padding-right: 0;
    height: 100%;
    border-right: 1px solid var(--gray-e6);
    flex-direction: row-reverse;
    @include flex-center-vertical;
    justify-content: space-around;
  }

  & > div:last-child {
    flex-grow: 19;
  }

  button {
    padding: 0;
    margin: 0;
    position: absolute;
    top: 50%;
    color: var(--green-light);
    height: 16px;
    left: 4px;
    @include flex-center-vertical;
    @include fontSize(18px);
    transform: translateY(-50%);
  }
}

//btn
.btn {
  line-height: 1.21;
  text-align: center;
  border: none;
  text-decoration: none;
  min-width: 123px;
  height: 40px;
  border: 1px solid var(--gray-e6);
  cursor: pointer;
  font-family: var(--fontFamily);
  font-weight: normal;
  padding: 0 ratio(2);
  transition: all 0.25s;
  @include fontSize(14px);
  border-radius: 4px;
  @include flex-center;

  &.disable,
  &[disabled="disabled"],
  &:disabled {
    cursor: not-allowed;
    opacity: 0.65;
  }
}

.btn--sm {
  height: 36px;
}

.btn--w-sm {
  width: 98px;
}

.btn--lg {
  height: 48px;
}

.btn-default {
  color: var(--gray-dark);
  background-color: var(--white);
  border-color: var(--white);
  @include fontSize(12px);

  &:hover {
    background-color: var(--gray-e6);
    border-color: var(--gray-e6);
  }
}

.btn--blue,
.btn--green {
  color: var(--white);
  background-color: var(--primary-color);
  border-color: var(--primary-color);

  &:hover {
    background-color: var(--primary-color-darker);
    border-color: var(--primary-color-darker);
  }
}

.btn--light {
  color: var(--text-primary);
  background-color: var(--white);
  border-color: var(--primary-color);

  &:hover {
    color: var(--white);
    background-color: var(--primary-color);
    border-color: var(--primary-color);
  }
}

.btn--disable {
  color: var(--gray-cc);
  background-color: transparent;
  border-color: var(--gray-cc);

  &:hover {
    color: var(--white);
    background-color: var(--gray-cc);
    border-color: var(--gray-cc);
  }
}

.btn--border-gray {
  color: var(--gray-99);
  background-color: var(--white);
  border-color: var(--gray-cc);
  @include fontSize(13px);

  &:hover {
    background-color: var(--gray-e6);
    border-color: var(--gray-e6);
  }
}

.btn--none-border {
  color: var(--text-primary);
  background-color: transparent;
  border-color: transparent;

  &:hover {
    color: var(--primary-color-darker);
  }
}

.btn--bookmark {
  min-width: auto;
  color: var(--gray-cc);
  background: none;
  border: none;
  padding: 0;
  @include size(18px);
  @include fontSize(18px);

  &:hover,
  &.is-bookmarked {
    color: var(--red);

    .icon-bx-heart {
      &:before {
        content: $icon-heart-fill;
      }
    }
  }
}

.btn--square {
  padding: 0;
  min-width: initial;
  @include size(30px);
  @include fontSize(18px);
}

//just find in typography
.btn--w-auto {
  display: inline-flex;
}

//checkbox
.checkmark[type="checkbox"] {
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;
  z-index: -1;

  & + label,
  & + input[type="hidden"] + label {
    width: 100%;
    @include flex-center-vertical;
    justify-content: flex-end;
    flex-direction: row-reverse;
    margin-bottom: 0;
    position: relative;
    cursor: pointer;
    flex-wrap: wrap-reverse;
    padding-right: ratio(4);

    &:after {
      @include size(16px);
      background-color: var(--white);
      content: "";
      margin-left: ratio(0.5);
      border-radius: 4px;
      transition: all 0.25s;
      top: 0;
      position: absolute;
      right: 0;
      border: solid 2px var(--gray-ee);
    }

    &:before {
      @include size(18px);
      content: $icon-check;
      @include fontSize(8px);
      top: 0;
      right: 0;
      position: absolute;
      text-align: center;
      color: var(--white);
      font-family: $icomoon-font-family;
      border: 1px solid var(--gray-ee);
      transform: scale(0);
      transition: all 0.25s;
      border-radius: 4px;
      z-index: 2;
      line-height: 2.4;
    }
  }

  &:not(:disabled) {
    & + label {
      &:hover {
        &:after {
          border-color: var(--primary-color);
        }
      }
    }
  }

  &:checked {
    & + label,
    & + input[type="hidden"] + label {
      &:before {
        transform: scale(1);
      }

      &:after {
        background-color: var(--primary-color);
      }
    }
  }
}

//radio button
.checkmark[type="radio"] {
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;
  z-index: -1;

  & + label {
    width: 100%;
    margin-bottom: 0;
    position: relative;
    cursor: pointer;
    padding-right: ratio(4);
    @include flex-center-vertical;
    justify-content: flex-end;
    flex-direction: row-reverse;
    flex-wrap: wrap-reverse;

    &:after {
      @include size(18px);
      background-color: var(--white);
      content: "";
      margin-left: ratio(0.5);
      border-radius: 50%;
      position: absolute;
      right: 0;
      border: solid 2px var(--gray-ee);
      transition: all 0.25s;
    }

    &:before {
      content: "";
      text-align: center;
      color: var(--white);
      background-color: var(--white);
      @include size(8px);
      right: 7px;
      position: absolute;
      border-radius: 50%;
      transition: all 0.25s;
      transform: scale(0);
      z-index: 2;
    }

    &:hover {
      &:after {
        border-color: var(--primary-color);
      }
    }
  }

  &:checked {
    & + label {
      color: var(--gray-dark);

      &:before {
        transform: scale(1);
      }
    }

    & + label {
      &:after {
        background-color: var(--primary-color);
        border-color: var(--primary-color);
      }
    }
  }
}

// switch button
.switch[type="checkbox"] {
  position: absolute;
  opacity: 0;
  z-index: -1;
  @include size(0);

  &[disabled],
  &:disabled {
    & + label {
      cursor: not-allowed;
    }
  }

  & + label {
    padding-right: ratio(3);
    display: flex;
    align-items: start;
    position: relative;
    @include fontSize(14px);
    cursor: pointer;

    &::before,
    &::after {
      content: "";
      position: absolute;
      inset-inline-start: 0;
    }

    &::before {
      background-color: var(--gray-cc);
      border-radius: 12px;
      @include size(32px, 20px);
    }

    &::after {
      border-radius: 50%;
      @include size(16px);
      inset-inline-start: 14px;
      inset-block-start: 2px;
      background-color: var(--white);
      color: var(--white);
    }
  }

  &:checked {
    & + label {
      &::before {
        background-color: var(--primary-color);
      }

      &::after {
        inset-inline-start: 2px;
      }
    }
  }
}

//loading
.c-loading {
  .loading {
    width: 335px;
    max-width: 100%;
    margin: ratio(6) auto;
    background-color: var(--white);
    border-radius: 4px;
    overflow: hidden;

    @include for-size("tablet-down") {
      margin: ratio(2) auto;
    }

    .loading__progress-bar {
      height: 4px;
      background-color: var(--gray-ee);
      position: relative;
      overflow: hidden;
    }

    .loading__progress-bar-inner {
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      background-color: var(--primary-color);
      width: 20%;
      transition: all 0.25s;
    }

    .loading__progress-bar-inner--auto-fill {
      animation: load 40s normal forwards;
    }

    .loading__inner {
      padding: ratio(6) ratio(2.5);
      text-align: center;
      @include flex-center-vertical;
      flex-direction: column;

      @include for-size("phone-only") {
        width: 100%;
        margin: ratio(2) auto;
      }
    }

    .loading__img {
      max-height: 165px;
      max-width: 165px;
      margin: 0 auto ratio(4);
      display: block;
    }

    .loading__title {
      margin-bottom: ratio(1);
      @include fontSize(19px);
      font-weight: bold;
      line-height: 1.74;
      display: block;
    }

    .loading__description {
      margin: 0;
      line-height: 2;
      @include fontSize(14px);
    }
  }
}

//modal
.c-modal {
  .modal {
    position: fixed;
    inset: 0;
    display: none;
    overflow: hidden;
    z-index: 100;
    background-color: rgba(var(--white-rgb), 0.8);
    overflow-y: auto;

    .modal__wrapper {
      margin-left: auto;
      margin-right: auto;

      @include for-size(not-phone) {
        width: 890px;
        position: relative;
      }
    }

    &.modal--black {
      background-color: rgba(var(--black-rgb), 0.5);
    }

    &.modal--flex {
      @include for-size("tablet-down") {
        z-index: 101;
      }

      & > .container {
        flex-grow: 0;

        @include for-size("tablet-down") {
          width: 100%;
        }
      }
    }

    &.show-modal {
      &.fade {
        opacity: 1;

        &:not(.modal--flex) {
          display: block;
        }

        &.modal--flex {
          @include flex-center;
        }
      }

      .modal__wrapper {
        transform: translate(0, 0);
      }

      @include for-size(phone-only) {
        padding: 0 ratio(2);
      }
    }

    &.fade {
      opacity: 0;
      transition: all 0.25s;

      .modal__wrapper {
        transition: all 0.25s;
      }
    }

    .modal__content {
      background-color: var(--white);
      position: relative;
      border-radius: 4px;
      box-shadow: 0 2px 6px rgba(var(--black-rgb), 0.16);
      padding-bottom: ratio(6);
    }

    .modal__header {
      display: flex;
      justify-content: space-between;
      padding: ratio(4) ratio(2);
      border-bottom: 1px solid var(--gray-ee);
      margin: 0 ratio(2);

      h4 {
        @include fontSize(20px);
        font-weight: bold;
        margin: 0;

        @include for-size(phone-only) {
          @include fontSize(16px);
          font-weight: normal;
        }
      }

      button {
        display: flex;

        i {
          color: var(--red);
        }
      }
    }

    .modal__body {
      padding: 0 ratio(2);

      @include for-size(phone-only) {
        height: calc(100vh - 270px);
        height: calc((var(--vh, 1vh) * 100) - 270px);
        overflow: auto;
      }
    }

    .modal__footer {
      @include flex-center-vertical;
      margin-top: ratio(2);
      padding: ratio(2);
      border-top: 1px solid var(--gray-ed);

      @include for-size("tablet-down") {
        height: 72px;
        padding: 0 ratio(2);
        justify-content: space-between;
        margin: 0;
      }
    }
  }
}

//tab
.c-tab {
  .tab {
    .tab__tabs {
      display: flex;
      height: 58px;
      justify-content: space-between;

      li {
        flex-grow: 1;
        @include flex-center;
        position: relative;

        a {
          color: var(--black);
          @include size(100%);
          @include flex-center;
          border-bottom: 1px solid var(--black);
        }

        &.active {
          a {
            border-bottom: none;
          }
        }
      }
    }

    .tab__content {
      & > div {
        display: none;

        &:first-child {
          display: block;
        }
      }
    }
  }
}

//alert
.alert {
  position: relative;
  padding: ratio(1);
  margin-bottom: ratio(2);
  border: 1px solid transparent;
  border-right-width: 4px;
  border-radius: 0.25rem;
  @include fontSize(13px);
  display: flex;
  align-items: flex-start;
  background-color: var(--white);

  i {
    margin-left: ratio(0.5);
    @include fontSize(18px);
  }

  .close {
    background: none;
    border: none;
    @include fontSize(16px);
    cursor: pointer;
    z-index: 2;
    height: 15px;
    line-height: 1;
  }
}

.alert--success {
  color: var(--color-4);
  border-color: var(--color-4);

  button {
    color: var(--color-4);
  }
}

.alert--warning {
  color: var(--yellow);
  border-color: var(--yellow);

  button {
    color: var(--yellow);
  }
}

.alert--primary {
  color: var(--primary-color);
  border-color: var(--primary-color);

  button {
    color: var(--primary-color);
  }
}

.alert--danger,
.alert--error {
  color: var(--red);
  border-color: var(--red);

  button {
    color: var(--red);
  }
}

.alert--info {
  color: var(--blue);
  border-color: var(--blue);

  button {
    color: var(--blue);
  }
}

//validation
.validation {
  font-weight: bold;
  margin: ratio(0.5) 0;
  @include fontSize(12px);
}

.validation--error {
  .react-select__indicator-separator {
    border-color: var(--red);
  }

  .react-select__dropdown-indicator {
    color: var(--red);
  }
}

.validation--success {
  color: var(--color-4);
}

/**************booking objects********/
//cart horizontal
.cart-horizontal {
  background-color: var(--white);
  overflow: hidden;
  @include flex-center-vertical;
  border-radius: 4px;
  box-shadow: 0px 1px 2px 0 rgba(var(--black-rgb), 0.1);
  height: 165px;

  @include for-size("tablet-down") {
    height: 105px;
  }

  &:not(:last-child) {
    margin-bottom: ratio(2.5);

    @include for-size("tablet-down") {
      margin-bottom: ratio(1.5);
    }
  }

  .cart-horizontal__img {
    @include size(165px, 100%);
    background-size: cover;
    background-position: center;

    @include for-size("tablet-down") {
      width: 105px;
    }

    & + div {
      padding: 0 ratio(3);
      width: calc(100% - 165px);

      @include for-size("tablet-down") {
        padding: 0 ratio(1);
        width: calc(100% - 105px);
      }
    }
  }

  .cart-horizontal__detailes {
    flex-direction: column;
    padding: 0 ratio(3);
    width: calc(100% - 165px);

    @include for-size("tablet-down") {
      padding: 0 ratio(1);
      width: calc(100% - 105px);
    }
  }

  .cart-horizontal__title {
    margin: 0 0 ratio(2);
    color: var(--gray-26);
    @include fontSize(15px);
    max-height: 48px;
    overflow: hidden;

    @include for-size("tablet-down") {
      font-weight: normal;
    }
  }

  .cart-horizontal__desc {
    @include fontSize(13px);
    color: var(--gray-dark);
    margin: 0 0 ratio(2);
    max-height: 40px;
    overflow: hidden;
    display: block;

    &.cart-horizontal__desc--gray {
      color: var(--color-5);
    }

    @include for-size("tablet-down") {
      display: none;
    }
  }

  .cart-horizontal__date {
    @include fontSize(12px);
    color: var(--color-5);
  }
}

.cart-horizontal--small {
  padding: ratio(2);
  height: 112px;

  @include for-size("tablet-down") {
    height: 105px;
  }

  .cart-horizontal__title {
    @include fontSize(19px);
    margin-bottom: ratio(0);
    line-height: 1.8;
    font-weight: bold;

    @include for-size("desktop") {
      height: 34px;
    }

    @include for-size("tablet-down") {
      @include fontSize(14px);
    }
  }
  .cart-horizontal__review-score-badge {
    width: 24px;
    height: 24px;
    @include fontSize(12px);
    margin-top: ratio(0.25);
  }
  .cart-horizontal__img {
    height: 100%;
    width: 80px;
  }
  .cart-horizontal__desc {
    margin-top: 0;
    margin-bottom: 0;
  }
  .cart-horizontal__detailes {
    height: 100%;
    display: flex;
    justify-content: space-between;
  }
}

//cart vertical
.cart-vertical {
  background-color: var(--white);
  overflow: hidden;
  height: 350px;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  box-shadow: 0px 1px 2px 0 rgba(var(--black-rgb), 0.1);

  &:not(:last-child) {
    @include for-size("tablet-down") {
      margin-bottom: ratio(1);
    }
  }

  .cart-vertical__img {
    @include size(100%, 165px);
    background-size: cover;
    background-position: center;

    & + div {
      height: calc(100% - 165px);
      padding: ratio(3);

      @include for-size("tablet-down") {
        padding: ratio(2);
      }
    }
  }

  .cart-vertical__title {
    margin: 0 0 ratio(1);
    color: var(--gray-26);
    @include fontSize(15px);
    height: 48px;
    overflow: hidden;
  }

  .cart-vertical__desc {
    @include fontSize(13px);
    color: var(--color-5);
    margin: 0 0 ratio(2);
    height: 44px;
    overflow: hidden;
  }

  .cart-vertical__date {
    @include fontSize(12px);
    color: var(--color-5);
  }
}

//cart full image
.cart-full-image {
  position: relative;
  @include size(100%, 350px);
  overflow: hidden;
  box-shadow: 0px 1px 2px 0 rgba(var(--black-rgb), 0.1);
  border-radius: 4px;
  display: flex;
  align-items: flex-end;

  &:not(:last-child) {
    margin-bottom: ratio(2.5);
  }

  @include for-size("tablet-down") {
    height: 320px;

    &:not(:last-child) {
      margin-bottom: ratio(1);
    }
  }

  &:after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    @include size(100%);
    background-color: rgba(var(--black-rgb), 0.4);
  }

  a {
    color: var(--white);
  }

  .cart-full-image__img {
    position: absolute;
    @include size(100%);
    top: 0;
    left: 0;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }

  .cart-full-image__description {
    position: relative;
    z-index: 1;
    color: var(--white);
    padding: ratio(4);
    width: 100%;

    @include for-size("tablet-down") {
      padding: ratio(3);
    }
  }

  .cart-full-image__category {
    padding: ratio(0.25) ratio(1);
    border: solid 1px var(--white);
    margin-top: 0;
    margin-bottom: ratio(1.5);
    border-radius: 4px;
    @include fontSize(12px);
  }

  .cart-full-image__title {
    display: flex;
    flex-direction: column;
    margin-top: ratio(1.5);

    span {
      line-height: 1.74;
      font-weight: bold;

      @include for-size("tablet-down") {
        @include fontSize(15px);
      }
    }

    p {
      margin: ratio(1.5) 0 0;
      @include fontSize(13px);
      line-height: 1.62;
    }
  }

  .cart-full-image__info {
    @include flex-center-vertical;
    flex-wrap: wrap;
    margin-top: ratio(1.5);

    li {
      @include fontSize(12px);
      margin-top: ratio(1);

      &:not(:last-child) {
        border-left: 1px solid var(--white);
        padding-left: ratio(1);
        margin-left: ratio(1);
      }
    }
  }
}

// hotel cart
.hotel-cart {
  border-radius: 4px;
  box-shadow: 0px 1px 2px 0 rgba(var(--black-rgb), 0.1);
  background-color: var(--white);
  overflow: hidden;
  flex-shrink: 0;

  @include for-size("tablet-down") {
    display: flex;
    flex-direction: column;
    width: 160px;
  }

  &.hotel-cart--large {
    .hotel-cart__img {
      @include for-size("desktop") {
        height: 240px;
      }
    }

    .hotel-cart__details {
      @include for-size("desktop") {
        padding-bottom: 0;
        height: 130px;
      }

      .hotel-cart__title {
        @include for-size("desktop") {
          height: 25px;
        }
      }

      .hotel-cart__hotel-address {
        margin-top: ratio(0.5);
      }

      .hotel-cart__review-score {
        margin-top: ratio(2);
      }
    }
  }

  .hotel-cart__img {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    display: block;
    position: relative;
    height: 198px;

    @include for-size("tablet-down") {
      height: 110px;
    }

    .hotel-cart__review-score {
      .hotel-cart__review-score-badge {
        position: absolute;
        top: 4px;
        left: 4px;
        @include size(24px);
        @include fontSize(12px);
      }
    }
  }

  .hotel-cart__details {
    display: block;
    padding: ratio(2);

    @include for-size("tablet-down") {
      padding: ratio(1);
      height: 70px;
    }

    div {
      &.hotel-cart__title {
        @include flex-center;

        i {
          margin: 0 ratio(1.5);
        }
      }
    }
  }

  .hotel-cart__title {
    color: var(--gray-26);
    margin: 0;
    font-weight: bold;
    display: block;
    @include fontSize(17px);
    line-height: 1.48;
    height: 54px;
    overflow: hidden;

    @include for-size("tablet-down") {
      @include fontSize(12px);
      height: 34px;
    }
  }

  .hotel-cart__hotel-address {
    color: var(--color-5);
    @include fontSize(12px);
    line-height: 1.3;
    display: block;
    height: 29px;
    overflow: hidden;
    margin-top: ratio(1.5);

    @include for-size("tablet-down") {
      height: 15px;
      margin-top: ratio(0.5);
    }
  }

  .hotel-cart__review-score {
    margin-top: ratio(1);
    display: flex;
  }

  .hotel-cart__review-score-badge {
    color: var(--white);
    @include size(32px);
    background-color: var(--blue-cerulean);
    font-family: var(--fontFamilyEnNum);
    border-radius: 5px 5px 0 5px;
    @include fontSize(15px);
    @include flex-center;
  }

  .hotel-cart__review-score-content {
    margin-right: ratio(1);

    span {
      color: var(--color-5);
      @include fontSize(12px);
      line-height: 1.4;
      display: block;
    }
  }

  .hotel-cart__btn-reserve {
    border-radius: 4px;
    margin: ratio(3) ratio(2) ratio(2) ratio(2);

    @include for-size("tablet-down") {
      display: none;
    }
  }
}

// tour-cart
.tour-cart {
  border-radius: 4px;
  box-shadow: 0px 1px 2px 0 rgba(var(--black-rgb), 0.1);
  background-color: var(--white);
  overflow: hidden;

  @include for-size("desktop") {
    height: 454px;
  }

  .tour-cart__img {
    display: block;
    @include size(100%, 198px);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    @include for-size("tablet-down") {
      height: 110px;
    }
  }

  .tour-cart__title {
    @include fontSize(19px);
    font-weight: bold;
    line-height: 1.35;
    margin: 0;
    margin-bottom: ratio(1.5);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    @include for-size("tablet-down") {
      @include fontSize(12px);
      margin-bottom: ratio(0.5);
      line-height: 1.93;
    }

    a {
      color: var(--gray-26);
    }
  }

  .tour-cart__details {
    padding: ratio(2);

    @include for-size("tablet-down") {
      padding: ratio(1);
    }

    li {
      margin-bottom: ratio(1.5);
      @include fontSize(12px);
      @include flex-center-vertical;

      @include for-size("tablet-down") {
        line-height: 1.67;
        margin-bottom: ratio(0.5);
      }

      i {
        color: var(--gray-cc);
        @include fontSize(14px);
      }

      span {
        margin-right: ratio(1);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .suggested-tour__item-night {
        @include size(60px, 24px);
        border-radius: 4px;
        background-color: var(--white);
        border: 1px solid var(--gray-ed);
        display: inline-flex;
        align-items: center;
        justify-content: center;

        @include for-size("tablet-down") {
          margin-right: 0;
          border: none;
          width: auto;
          height: auto;
          color: var(--gray-99);
        }
      }
    }
  }

  .tour-cart__footer {
    display: inline-flex;
    width: 100%;
    align-items: flex-end;
  }

  .tour-cart__price {
    width: 100%;
    text-align: left;

    @include for-size("tablet-down") {
      width: auto;
      margin-right: auto;
      padding-right: 0;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    & > div {
      display: flex;
      justify-content: flex-end;
    }
  }

  .tour-cart__price-text {
    display: block;
    color: var(--gray-99);
    line-height: 1.73;
    @include fontSize(11px);
    margin: 0;
  }

  .tour-cart__price-item {
    display: inline-flex;
    align-items: center;
    white-space: nowrap;

    &::after {
      content: "+";
      margin: auto ratio(0.5);
    }

    &:last-child {
      &::after {
        display: none;
      }
    }

    label {
      font-weight: bold;
      @include fontSize(19px);

      @include for-size("desktop") {
        margin-left: ratio(0.5);
      }

      @include for-size("tablet-down") {
        @include fontSize(12px);
      }
    }

    span {
      @include fontSize(14px);
    }
  }

  &:not(.tour-cart--show-all) {
    ul {
      li {
        &:nth-child(1),
        &:nth-child(3),
        &:nth-child(4) {
          @include for-size("tablet-down") {
            display: none;
          }
        }

        &:nth-child(2) {
          span {
            &:not(.suggested-tour__item-night) {
              @include for-size("tablet-down") {
                display: none;
              }
            }
          }
        }

        i {
          @include for-size("tablet-down") {
            display: none;
          }
        }
      }
    }

    .tour-cart__price-text {
      @include for-size("tablet-down") {
        display: none;
      }
    }
  }
}

// link-card
.link-card {
  display: flex;
  flex-wrap: wrap;
  gap: ratio(2);
  margin-bottom: ratio(3);

  &.link-card--sm {
    .link-card__item {
      @include for-size("desktop") {
        width: calc((100% - 48px) / 4);
      }
    }
  }

  .link-card__item {
    @include flex-center-vertical;
    justify-content: space-between;
    transition: all 0.25s;
    gap: ratio(1);
    border-radius: 8px;
    border: 1px solid var(--gray-ee);
    @include fontSize(14px);
    padding: ratio(1.5) ratio(2);
    color: var(--gray-70);
    width: calc((100% - 32px) / 3);
    background-color: var(--white);

    @include for-size("tablet-down") {
      width: 100%;
    }

    &:hover {
      color: var(--text-primary);
      border-color: var(--bc-primary-light);
      background-color: var(--bc-primary-light);
    }

    i {
      @include fontSize(20px);
    }
  }
}

//blue score box
.review-score-badge {
  color: var(--white);
  @include size(32px);
  background-color: var(--blue-cerulean);
  font-family: var(--fontFamilyEnNum);
  border-radius: 5px 5px 0 5px;
  @include fontSize(15px);
  @include flex-center;
}

//just in typography
.bg-box {
  display: block;
  direction: ltr;
  padding: ratio(2);
  margin: ratio(3) 0;
  @include fontSize(13px);
  line-height: 1.42857143;
  color: var(--gray46);
  word-break: break-all;
  word-wrap: break-word;
  background-color: var(--gray-f9);
  border: 1px solid var(--gray-ed);
  border-radius: 4px;
}

.img-footnote {
  text-align: center;

  & > div {
    display: inline-block;

    img {
      display: block;
      margin-right: auto;
      margin-left: auto;
      width: 100%;
    }
  }

  .footnote {
    @include fontSize(13px);
    padding: ratio(1) ratio(2) ratio(1) 0;
    border-right: 3px solid var(--primary-color);
    text-align: right;
    margin-top: ratio(1);
  }
}

.qoute {
  border-right: 3px solid var(--primary-color);
  padding-right: ratio(3);
  margin-top: ratio(5);
  margin-bottom: ratio(5);

  p {
    font-weight: bold;
    @include fontSize(19px);
  }

  .qoute-info {
    color: var(--gray-99);
    @include fontSize(13px);
  }
}

//just in typography
.evaluation {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin: ratio(4) 0;

  @include for-size("tablet-down") {
    margin: ratio(2) 0;
    flex-wrap: wrap;
  }
  //just in typography
  .evaluation__title {
    display: block;
    font-weight: bold;
    margin: 0 0 ratio(2);
    @include fontSize(16px);
  }

  //just in typography
  .evaluation__positive,
  .evaluation__negative {
    width: calc(50% - 8px);

    @include for-size("tablet-down") {
      width: 100%;
    }

    &:not(:last-child) {
      @include for-size("tablet-down") {
        margin-bottom: ratio(2);
      }
    }
  }

  //just in typography
  .evaluation__positive {
    li {
      &::before {
        content: $icon-success;
        color: var(--color-4);
      }
    }
  }

  //just in typography
  .evaluation__negative {
    li {
      &::before {
        color: var(--red);
        content: $icon-close-circle;
      }
    }
  }

  ul {
    li {
      position: relative;
      padding-right: ratio(3);
      line-height: 2;

      &:not(:last-child) {
        margin-bottom: ratio(1);
      }

      &::before {
        content: "";
        font-family: $icomoon-font-family;
        position: absolute;
        right: 0;
        font-weight: bold;
        @include fontSize(16px);
        line-height: 1.75;
      }
    }
  }
}

//just in typography
.related-article {
  border: 1px solid var(--gray-ee);
  margin: ratio(3) 0;
  border-radius: 4px;
  padding: ratio(2);
  @include fontSize(14px);
  line-height: 1.93;

  @include for-size("tablet-down") {
    margin: ratio(2) 0;
  }

  .related-article__title {
    margin: 0 0 ratio(2);
    font-weight: bold;
    display: block;
    @include fontSize(16px);
  }

  ul {
    margin: 0 !important;
    padding-right: ratio(0.5);

    li {
      position: relative;
      padding-right: ratio(2.5);
      display: flex;
      align-items: flex-start;

      &:not(:last-child) {
        margin-bottom: ratio(1);
      }

      &::before {
        content: "";
        @include size(4px);
        background-color: var(--color-4);
        display: block;
        border-radius: 50%;
        position: absolute;
        right: 0;
        top: 12px;
      }

      a {
        color: var(--gray-16);
        transition: all 0.25s ease-in;

        &:hover {
          color: var(--color-4);
        }
      }
    }
  }
}

.box-bg {
  background-color: var(--gray-f9);
  border-color: var(--gray-ed);
  border-radius: 4px;
  padding: ratio(1.5) ratio(2);
  margin: ratio(3) 0;
  color: var(--gray46);

  &.blue,
  &.green,
  &.red {
    color: var(--white);
  }

  &.blue {
    background-color: var(--blue);
  }

  &.green {
    background-color: var(--color-4);
  }

  &.red {
    background-color: var(--red);
  }

  p {
    margin: 0;

    &:not(:last-child) {
      margin-bottom: ratio(2);
    }
  }
}

.credit-refresh-app {
  @include for-size("desktop") {
    border: 1px solid var(--primary-color);
    background: none;
    color: var(--primary-color);
    @include size(28px);
    min-width: initial;
  }

  @include for-size("tablet-down") {
    margin-right: ratio(1.5);
    min-width: unset;
    @include size(30px);
  }

  &:hover {
    background-color: var(--primary-color);
    color: var(--white);
  }

  i {
    @include fontSize(20px);

    &.credit-refresh-loading {
      animation: rotator linear 0.8s infinite;
    }
  }
}

.faq {
  margin-bottom: ratio(3);

  .faq__title {
    margin: 0 0 ratio(2);
    font-weight: bold;
    display: block;
    @include fontSize(24px);
  }

  .faq__items {
    padding-right: ratio(2);
  }

  .faq__item {
    &:not(:last-child) {
      margin-bottom: ratio(2);
    }
  }

  .faq__item-title {
    @include fontSize(20px);
    margin: 0 0 ratio(1);
    font-weight: bold;
  }

  .faq__item-content {
    p {
      margin: 0;

      &:not(:last-child) {
        margin-bottom: ratio(2);
      }
    }
  }
}

.c-survey {
  .survey {
    height: 68px;
    display: block;

    @include for-size("tablet-down") {
      height: 42px;
    }
  }

  .survey__image {
    @include size(100%);
    object-fit: cover;
    object-position: center;
  }
}

// circle loading
.circle-loading {
  @include size(60px);
  transform: rotate(-90deg);
  stroke-linecap: round;
  stroke-width: 4;
  fill: none;

  &.circle-loading--small {
    @include size(30px);
  }

  .internal-circle,
  .external-circle {
    stroke: var(--primary-color);
    stroke-dashoffset: 0;
    transform-origin: center;
  }

  .internal-circle {
    stroke-dasharray: 187;
    animation: internal 1s ease-in-out infinite;
    opacity: 0.4;
  }

  .external-circle {
    stroke-dasharray: 312;
    animation: external 1s linear infinite;
    opacity: 0.9;
  }
}

@keyframes internal {
  0% {
    stroke-dashoffset: 187;
  }

  25% {
    stroke-dashoffset: 80;
  }

  100% {
    stroke-dashoffset: 187;
    transform: rotate(360deg);
  }
}

@keyframes external {
  0% {
    stroke-dashoffset: 312;
    transform: rotate(70deg);
  }

  60% {
    stroke-dashoffset: -312;
  }

  100% {
    stroke-dashoffset: -312;
    transform: rotate(450deg);
  }
}

//dot dot loading(line loading)
.spinner {
  width: 70px;
  text-align: center;

  & > div {
    @include size(5px);
    background-color: var(--primary-color);
    border-radius: 100%;
    display: inline-block;
    -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  }

  .bounce2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
  }

  .bounce1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
  }
}

@-webkit-keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
  }

  40% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

.bounce-loading {
  position: absolute;
  inset: 0;
  z-index: 1;
  text-align: center;
  @include flex-center;

  & > span {
    margin-right: ratio(0.5);
    @include size(12px);
    background-color: var(--primary-color);
    border-radius: 2em;
    display: inline-block;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both;

    &.bounce1 {
      animation-delay: -0.32s;
    }

    &.bounce2 {
      animation-delay: -0.16s;
    }
  }

  &.bounce-loading--white {
    & > span {
      background-color: var(--white);
    }
  }
}

@keyframes sk-bouncedelay {
  0%,
  80%,
  100% {
    transform: scale(0);
  }

  40% {
    transform: scale(1);
  }
}

@keyframes rotator {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes load {
  0% {
    width: 5px;
  }

  100% {
    width: 100%;
  }
}

.tooltip {
  display: inline-flex;
  flex-direction: column;
  position: relative;

  &:hover {
    .tooltip__content {
      opacity: 100;
      visibility: visible;
    }
  }

  .tooltip__text {
    @include fontSize(12px);

    @include for-size("desktop") {
      cursor: pointer;
    }
  }

  .tooltip__content {
    order: -1;
    z-index: 1;
    position: absolute;
    top: -12px;
    right: 50%;
    transform: translate(50%, -100%);
    border-radius: 8px;
    white-space: nowrap;
    opacity: 0;
    visibility: hidden;
    padding: ratio(1) ratio(1.5);
    border: 2px solid transparent;
    transition: all 0.25s;
    color: var(--white);
    border-color: var(--gray-33);
    background-color: var(--gray-33);
    @include fontSize(10px);

    &::after {
      @include triangle("down", 5px, var(--gray-33));
    }

    @include for-size("tablet-down") {
      display: none;
    }
  }
}
