﻿.c-header {
  .header {
    background-color: var(--white);
    @include fontSize(14px);
    transition: all 0.25s;

    &.is-open {
      @include for-size("tablet-down") {
        z-index: 110;
      }

      .survey {
        @include for-size("tablet-down") {
          display: none;
        }
      }
    }

    &.is-fixed {
      position: fixed;
      top: 0;
      right: 0;
      left: 0;
      z-index: 100;
      border-bottom: 1px solid var(--gray-ee);
    }

    &.is-scroll-down {
      transform: translate3d(0, -100%, 0);
    }

    .header__top-row {
      background-color: var(--footer-header-bc);
    }

    .header__top-container {
      height: 60px;
      @include flex-center-vertical;
      justify-content: space-between;
      background-color: var(--footer-header-bc);

      @include for-size(desktop-small) {
        padding-left: ratio(1);
      }

      @include for-size(tablet-down) {
        height: 48px;
        padding: 0 0 0 ratio(2);
      }

      & > div {
        @include flex-center-vertical;
      }

      .header__account-btns {
        @include for-size(tablet-down) {
          display: none;
        }
      }

      .header__user-account {
        @include flex-center-vertical;

        & > a {
          height: 32px;
          min-width: initial;
          padding: 0 ratio(1.5);
          background-color: var(--white);
          display: inline-block;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          @include fontSize(12px);
          border-radius: 4px;

          &:first-child {
            @include for-size(desktop) {
              margin-left: ratio(2);
            }
          }
        }

        .header__btn-signin,
        .header__btn-track-order {
          height: 40px;
          padding: 0 ratio(2);
          @include flex-center;
          color: var(--white);
          border: 1px solid transparent;
          border-radius: 4px;
          transition: all 0.25s;
          background: none;
        }

        .header__btn-signin {
          border-color: var(--primary-color);
          background-color: var(--primary-color);

          &:hover {
            background-color: var(--primary-color-darker);
            border-color: var(--primary-color-darker);
          }
        }

        .header__btn-track-order {
          border-color: var(--primary-color);
          background-color: var(--primary-color);

          &:hover {
            background-color: transparent;
            border-color: var(--white);
          }
        }

        // prevent show icon in order button in header desktop
        .header__btn-track-order-icon {
          @include for-size("desktop") {
            display: none;
          }
        }

        .header__btn-track-order--dark {
          border-color: var(--gray-26);
          background-color: var(--gray-26);
        }

        .header__btn-track-order--with-margin {
          @include for-size("desktop") {
            margin-right: ratio(1.5);
          }

          @include for-size("tablet-down") {
            min-height: 26px;
          }
        }

        .header__btn-track-order--refound {
          @include for-size("tablet-down") {
            min-width: initial;
            width: auto;
            padding: 0 ratio(1);
            height: 32px;
            @include fontSize(10px);
          }

          &::after {
            @include for-size("tablet-down") {
              display: none;
            }
          }
        }

        .header__btn-track-order--with-icon {
          @include for-size("tablet-down") {
            height: 100%;
            border: none;
            background-color: transparent;

            & > i {
              @include fontSize(18px);
              @include size(18px);
            }
          }
        }

        .header__btn-user-profile {
          display: none;
        }

        .header__user-menu {
          & > li {
            position: relative;

            @include for-size(desktop) {
              &:hover {
                & > ul {
                  opacity: 1;
                  visibility: visible;
                  margin-top: 0;
                }

                .header__user-menu-arrow {
                  transform: rotate(180deg);
                }
              }
            }

            .header__user-name {
              color: var(--white);
              padding: ratio(1) 0;
              @include flex-center-vertical;

              .header__user-menu-arrow {
                transition: transform 0.25s;
              }

              span {
                margin: 0 ratio(1);
                @include fontSize(13px);
              }

              i {
                @include fontSize(20px);
              }
            }

            ul {
              position: absolute;
              top: 100%;
              right: 0;
              background-color: var(--white);
              box-shadow: 0 2px 8px 0 rgba(var(--black-rgb), 0.08);
              border-radius: 4px;
              padding: ratio(3);
              width: 230px;
              z-index: 10;
              opacity: 0;
              visibility: hidden;
              margin-top: ratio(1);
              transition: all 0.25s;

              li {
                margin-bottom: ratio(1.5);

                &:not(:last-child) {
                  margin-bottom: ratio(1.5);
                }

                a {
                  color: var(--gray-dark);
                  @include fontSize(14px);
                  transition: color 0.2s;
                  width: 100%;
                  display: block;

                  &:hover {
                    color: var(--text-primary);
                  }
                }
              }
            }
          }
        }
      }

      .header__company {
        &.is-open {
          .header__company-btn {
            background-color: var(--white);
            color: var(--text-primary);
          }

          .header__company-inner {
            opacity: 1;
            visibility: visible;
          }
        }
      }

      .header__company-inner {
        @include flex-center-vertical;
        flex-wrap: wrap;
        height: 38px;
        @include fontSize(13px);
        color: var(--white);
        padding: 0 ratio(0.5);

        @include for-size("desktop") {
          border: 1px solid var(--white);
          border-radius: 4px;
        }

        @include for-size("tablet-down") {
          flex-direction: column;
          align-items: flex-start;
          background-color: var(--white);
          @include size(100%, calc(100% - 48px));
          position: fixed;
          inset: 48px 0 0;
          padding: ratio(3);
          z-index: 100;
          opacity: 0;
          visibility: hidden;
          color: var(--gray-dark);
          @include fontSize(16px);
          transition: all 0.25s;
        }
      }

      .header__company-item {
        @include for-size("tablet-down") {
          display: flex;
          justify-content: space-between;
          border-bottom: 1px solid var(--gray-ed);
          padding-bottom: ratio(1.5);
          margin-bottom: ratio(1.5);
          width: 100%;
        }
      }

      .header__company-item-title {
        color: var(--gray-70);
        @include fontSize(14px);
        width: 120px;
      }

      .header__company-price {
        @include for-size("desktop") {
          min-width: 120px;
          text-align: center;
          display: block;
        }
      }

      .header__company-btn {
        @include size(40px);
        @include fontSize(20px);
        @include flex-center;
        color: var(--white);
        transition: all 0.25s;

        @include for-size("tablet-down") {
          height: 48px;
        }
      }

      .header__company-refresh {
        @include for-size("desktop") {
          border: 1px solid var(--white);
          background: none;
          color: var(--white);
          @include size(28px);
          min-width: initial;
        }

        @include for-size("tablet-down") {
          width: 100%;
          margin-top: ratio(1.5);
        }

        &:hover {
          background-color: var(--primary-color);
          border-color: var(--primary-color);
          color: var(--white);
        }

        i {
          @include fontSize(20px);
        }
      }

      .header__phone-number {
        @include fontSize(13px);
        margin-right: ratio(4);
        color: var(--white);
        direction: ltr;

        @include for-size("desktop-medium") {
          margin-right: ratio(2);
        }

        @include for-size(tablet-down) {
          display: none;
        }

        a {
          color: var(--white);
          @include flex-center-vertical;
        }

        i {
          @include fontSize(18px);
          margin-right: ratio(1);
        }
      }

      .header__logo {        
        justify-content: flex-end;
        @include size(160px, 100%);
        @include flex-center-vertical;
        justify-content: flex-end;
        width: var(--header-logo-width);
        height: var(--header-logo-height);

        @include for-size("tablet-down") {          
          width: var(--header-logo-width-phone);
          height: var(--header-logo-height-phone);
        }

        img {
          max-width: 100%;
          max-height: 100%;
        }
      }

      .header__toggler {
        position: relative;
        cursor: pointer;
        width: 48px;
        height: 100%;
        margin-left: ratio(1);
        @include flex-center-vertical;

        span {
          display: block;
          position: absolute;
          height: 2px;
          width: 20px;
          background: var(--white);
          border-radius: 9px;
          opacity: 1;
          left: 0;
          right: 0;
          margin: auto;
          transform: rotate(0deg);
          transition: all 0.25s;

          &:nth-child(1) {
            top: 18px;
          }

          &:nth-child(2),
          &:nth-child(3) {
            top: 23px;
          }

          &:nth-child(4) {
            top: 28px;
          }
        }

        &.open {
          background-color: var(--white);

          span {
            background-color: var(--primary-color);
          }

          & + .header__user-account {
            display: none;
          }
        }

        & + .header__user-account {
          & > a {
            &.header__btn-signin,
            &.header__btn-track-order {
              &:not(.header__btn-track-order--refound) {
                @include fontSize(0px);
                position: relative;
                @include size(32px);
                border-radius: 5px;
                padding: 0;

                &:after {
                  position: absolute;
                  font-family: $icomoon-font-family;
                  color: var(--white);
                  @include fontSize(18px);
                }
              }
            }

            &.header__btn-signin {
              &:after {
                content: $icon-user-circle;
              }
            }

            &.header__btn-track-order {
              margin-right: ratio(1.5);

              &:after {
                content: $icon-list;
              }
            }

            // prevent show text of order button in menu of header (shown just in mobile)
            .header__btn-track-order-text {
              display: none;
            }

            &.header__btn-track-order--dark {
              &:after {
                content: $icon-support;
              }
            }

            &.header__btn-track-order--with-margin {
              @include for-size("tablet-down") {
                padding: 0;
              }

              &:after {
                @include for-size("tablet-down") {
                  position: relative;
                  padding: 0 ratio(0.5);
                }
              }
            }
          }
        }
      }
    }

    .header__bottom-container {
      @include flex-center-vertical;

      @include for-size(desktop-medium) {
        padding: 0 ratio(2);
      }

      @include for-size(tablet-down) {
        position: relative;
        padding: 0 ratio(1);
      }

      .nav-bar > li {
        @include for-size(tablet-down) {
          &:not(.nav-bar__hotel):not(.nav-bar__plane):not(.nav-bar__tour) {
            display: none;
          }
        }
      }
    }

    .header__search {
      margin-right: ratio(3);
      position: relative;
      @include flex-center;

      .header__search-btn {
        @include fontSize(18px);
        @include flex-center;
        border: none;
        color: var(--gray-70);
        background-color: transparent;
        cursor: pointer;
        height: 60px;
      }

      .header__search-box {
        position: fixed;
        inset: 0;
        background: rgba(var(--black-rgb), 0.6);
        padding: ratio(3);
        opacity: 0;
        visibility: hidden;
        z-index: 100;
        @include size(100%);
        @include flex-center;
        transition: all 0.25s;

        &.js-search-active {
          opacity: 1;
          visibility: visible;
        }
      }
    }

    .header__search-input {
      @include flex-center-vertical;
      border: none;
      position: relative;
      height: 40px;
      padding: ratio(0.5);

      @include for-size(desktop) {
        width: 580px;
        border-radius: 4px;
      }

      @include for-size(tablet-down) {
        border: 1px solid var(--gray-e6);
        border-radius: 4px;
        padding: 0 ratio(0.5);
      }

      .header__search-line {
        @include size(0, 2px);
        background-color: var(--white);
        position: absolute;
        left: 0;
        bottom: 0;
        transition: all 0.25s;
      }

      input[type="text"] {
        width: calc(100% - 32px);
        height: 100%;
        background-color: transparent;
        border: none;
        @include fontSize(16px);

        @include for-size(desktop) {
          color: var(--white);
        }

        @include placeholder {
          @include for-size("desktop") {
            @include fontSize(19px);
            color: var(--white);
          }
        }

        &:focus {
          & + .header__search-line {
            width: 100%;
          }
        }
      }

      button {
        min-width: auto;
        @include fontSize(18px);

        @include for-size("desktop") {
          background-color: transparent;
          border: none;
        }

        @include for-size(tablet-down) {
          @include size(32px);
        }
      }
    }

    .header__menu-in-mobile {
      @include flex-center-vertical;
      justify-content: space-between;
      background-color: var(--footer-header-bc);
      @include size(100%, 48px);
      top: 0;
      right: 0;

      &.open-nav {
        width: 100%;
        position: fixed;

        & + .header__menu-container-in-mobile {
          opacity: 1;
          visibility: visible;
          margin-top: 0;
          z-index: 100;
          right: 0;
        }

        .header__menu-back {
          display: flex;
        }
      }

      & > div {
        height: 100%;
        display: flex;
      }

      .header__user-account {
        .header__user-menu {
          height: 100%;

          & > li {
            height: 100%;

            & > .header__user-name {
              height: 100%;
              width: 40px;
              justify-content: center;

              i {
                @include fontSize(24px);

                @include for-size(tablet-down) {
                  @include fontSize(20px);
                }
              }

              span {
                display: none;
              }

              .header__user-menu-arrow {
                display: none;
              }

              &.open {
                background: var(--white);
                color: var(--text-primary);

                i {
                  @include fontSize(20px);
                }

                & + ul {
                  position: fixed;
                  top: 48px;
                  width: 100%;
                  z-index: 100;
                  height: calc(100% - 48px);
                  opacity: 1;
                  visibility: visible;
                  margin-top: 0;
                  border-radius: 0;

                  li {
                    &:not(:last-child) {
                      border-bottom: 1px solid var(--gray-ee);
                      padding-bottom: ratio(1.5);
                    }
                  }
                }
              }
            }
          }
        }
      }

      & > button {
        background-color: transparent;
        border: none;
        color: var(--white);
        @include flex-center-vertical;
        display: none;

        i {
          @include fontSize(24px);
        }
      }
    }

    .header__menu-container-in-mobile {
      background-color: var(--gray-ed);
      width: 100%;
      height: calc(100% - 48px);
      position: fixed;
      right: -100%;
      top: 48px;
      overflow: auto;
      z-index: -1;
      opacity: 0;
      visibility: hidden;
      transition: all 0.25s;

      .header__search-container-in-mobile {
        box-shadow: 0 1px 0 0 var(--gray-ed);
        background-color: var(--white);
        padding: ratio(2);
      }
    }

    .header__phone-number-in-mobile {
      @include flex-center-vertical;
      justify-content: space-between;
      box-shadow: 0 1px 0 0 var(--gray-ed);
      padding: ratio(2);
      background-color: var(--white);
      margin-top: ratio(3);

      @include for-size("desktop") {
        display: none;
      }

      span {
        @include fontSize(13px);

        &:last-child {
          color: var(--green-light);

          i {
            margin-right: ratio(1);
            @include fontSize(16px);
          }
        }
      }

      a {
        i {
          margin-right: ratio(1);
        }
      }
    }

    .header__accounts-btn-in-mobile {
      .header__user-account {
        display: flex;
        flex-direction: column;
        margin-top: ratio(3);

        a {
          &.header__btn-signin,
          &.header__btn-track-order {
            background-color: var(--white);
            margin-left: 0;
            @include size(100%, 48px);
            border-radius: 0;
            justify-content: flex-start;
            padding: 0 ratio(2);
            color: var(--gray-dark);
          }

          &.header__btn-signin {
            border: none;
          }

          &.header__btn-track-order {
            border: none;
            border-top: 1px solid var(--gray-ed);
            @include fontSize(12px);
          }

          &.header__btn-user-profile {
            display: flex;

            &:hover {
              background-color: transparent;
              border-color: var(--white);
            }
          }
        }

        .header__btn-track-order-icon {
          display: none;
        }
      }

      .header__user-menu,
      .header__company-btn {
        display: none;
      }
    }

    .header__main-menu-in-mobile {
      @include flex-center-vertical;
      justify-content: space-between;
      box-shadow: 0 1px 0 0 var(--gray-ed);
      background-color: var(--white);
      margin-top: ratio(3);

      .header__nav-bar {
        @include for-size(tablet-down) {
          width: 100%;
        }

        .nav-bar {
          & > li {
            width: 100%;
            display: block;

            a {
              &:before {
                content: initial;
              }
            }

            &:not(.nav-bar__blog) {
              display: none;
            }

            &.open-sub-menu {
              .nav-bar__sub-menu {
                display: none;
              }
            }

            .nav-bar__menu-item {
              &.nav-bar__menu-item--has-sub-menu {
                color: var(--gray-dark);
                display: block;
                margin: 0 ratio(2);
                padding: 0;

                @include for-size(tablet-down) {
                  padding: ratio(1) 0;
                  color: var(--gray-dark);
                  height: 48px;
                  @include flex-center-vertical;

                  &:after {
                    font-family: $icomoon-font-family;
                    content: $icon-arrow-down;
                    position: absolute;
                    left: 8px;
                    top: 50%;
                    transform: translateY(-50%);
                    @include fontSize(20px);
                  }
                }
              }
            }

            & > ul.nav-bar__sub-menu {
              min-width: 100%;
              opacity: 1;
              visibility: visible;
              box-shadow: none;
              margin-top: 0;
              padding: 0;
              position: relative;
              top: auto;

              li {
                a {
                  padding: 0 ratio(5.5) ratio(1.5);

                  &:before {
                    content: initial;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
