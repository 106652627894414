﻿.c-accordion {
  .accordion {
    display: flex;
    margin-top: ratio(6);

    @include for-size("desktop") {
      background-color: var(--white);
      border-radius: 4px;
      box-shadow: 0px 1px 2px 0 rgba(var(--black-rgb), 0.1);
      overflow: hidden;
    }

    @include for-size("tablet-down") {
      flex-direction: column;      
      margin-top: ratio(3);
      padding: 0 ratio(2);
    }

    .accordion__main-title {
      @include fontSize(19px);
      font-weight: bold;
      padding: ratio(5);
      background-image: var(--primary-gradient);
      color: var(--white);
      position: relative;
      width: 340px;
      line-height: 1.74;
      margin: 0;

      @include for-size("tablet-down") {
        @include fontSize(15px);
        padding: ratio(4) ratio(2);
        @include size(100%, 240px);
        border-radius: 5px;
        margin-bottom: ratio(2);
      }

      &::after {
        content: "";
        position: absolute;
        inset: 0;
        @include size(100%);
        background-image: url("../../../Assets/Img/accordion-mask.svg");
        background-repeat: no-repeat;
        border-radius: 4px;
        background-position: center bottom;
      }
    }

    .accordion__title {
      @include for-size("desktop") {
        @include fontSize(19px);
        padding: ratio(2.5) ratio(3) 0;
        margin-bottom: ratio(2);
      }

      @include for-size("tablet-down") {
        @include fontSize(15px);
        padding: ratio(2) ratio(2) 0;
        margin-bottom: 0;
      }
    }

    .accordion__list {
      @include for-size("desktop") {
        width: calc(100% - 340px);
      }

      @include for-size("tablet-down") {
        background-color: var(--white);
        border-radius: 4px;
        box-shadow: 0px 1px 2px 0 rgba(var(--black-rgb), 0.1);
      }
    }

    .accordion__item {
      &:not(:last-child) {
        border-bottom: 1px solid var(--gray-ee);
      }
    }

    .accordion__header {
      @include flex-center-vertical;
      justify-content: space-between;
      padding: ratio(2);
      cursor: pointer;

      i {
        transition: transform 0.25s;        
        transform: rotate(0);
       color: var(--text-primary);
      }

      span,
      h3 {
        margin: 0;
        @include fontSize(15px);
        font-weight: bold;

        @include for-size("tablet-down") {
          @include fontSize(14px);
        }
      }
    }

    .accordion-open {
      i {
        transform: rotate(180deg);
      }
    }

    .accordion__content {
      line-height: 2;
      display: none;
      @include fontSize(14px);
      padding: ratio(2);

      @include for-size("tablet-down") {
        padding: 0 ratio(2);
      }
    }
  }
}
