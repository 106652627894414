﻿.c-not-found {
  .not-found {
    min-height: 100vh;
    @include flex-center;
    padding: ratio(2);

    .container {
      max-width: 1320px;
    }

    .not-found__container {
      @include flex-center-vertical;
      justify-content: space-between;
      background-color: var(--white);
      box-shadow: 0 1px 2px 0 rgba(var(--black-rgb), 0.1);
      border-radius: 4px;
      padding: ratio(10) ratio(8);
      flex-wrap: wrap;

      @include for-size("tablet-down") {
        padding: ratio(2);
      }
    }

    .not-found__right {
      position: relative;
      @include size(350px, 270px);
      @include flex-center;

      @include for-size("tablet-down") {
        @include size(100%, 200px);
        margin-bottom: ratio(4);
      }
    }

    .not-found__left {
      width: calc(100% - 430px);

      @include for-size("tablet-down") {
        width: 100%;
      }
    }

    .not-found__flag {
      &::before,
      &::after {
        content: "";
        position: absolute;
        background-color: var(--primary-color);
        top: 0;
        left: 0;
        border-radius: 2em;
      }

      &::before {
        @include size(140px, 12px);

        @include for-size("tablet-down") {
          @include size(80px, 10px);
        }
      }

      &::after {
        @include size(12px, 140px);

        @include for-size("tablet-down") {
          @include size(10px, 80px);
        }
      }
    }

    .not-found__flag--bottom {
      &::before,
      &::after {
        inset: initial 0 20px initial;
      }
    }

    .not-found__code {
      @include fontSize(140px);
      line-height: 0;

      @include for-size("tablet-down") {
        @include fontSize(100px);
      }
    }

    .not-found__title {
      @include for-size("tablet-down") {
        @include fontSize(20px);
      }
    }

    .not-found__subtitle {
      color: var(--gray-70);

      @include for-size("tablet-down") {
        @include fontSize(14px);
        line-height: 2;
      }
    }

    .not-found__link {
      display: inline-flex;

      @include for-size("phone-only") {
        width: 100%;
      }
    }

    .not-found__footer {
      @include flex-center-vertical;
      justify-content: space-between;
      border-top: 1px solid var(--gray-ee);
      padding-top: ratio(4);
      margin-top: ratio(10);
      width: 100%;

      @include for-size("tablet-down") {
        flex-direction: column-reverse;
        padding-top: ratio(3);
        margin-top: ratio(7);
      }
    }

    .not-found__footer-img {
      @include size(168px, 70px);
      @include flex-center-vertical;      
      
      img {
        max-width: 100%;
        max-height: 100%;
      }
    }

    .not-found__footer-description {
      width: calc(100% - 200px);
      @include fontSize(12px);
      text-align: justify;
      line-height: 2;

      @include for-size("tablet-down") {
        width: 100%;
        margin-bottom: ratio(3);
      }
    }
  }
}
